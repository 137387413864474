<template>
  <div class="users">
    <page-title :title="user ? user.name : ''" :items="items"></page-title>

    <v-card class="pa-3 rounded-0 flat custom__card" v-if="user">
      <button class="btn" @click="print">Печать</button>
        <div v-if="isPrint">
          
          <div>ФИО: <strong>{{ user.name }}</strong></div>
          <div>E-mail: <strong>{{ user.email }}</strong></div>
          <div>Дата рождения: <strong>{{ birthdayFormat(user.birthday) }}</strong></div>
          <div>Специалист: <strong>{{ user.doctor.name }}</strong></div>
          <div>Пол: <strong>{{ user.sex == 'f' ? 'Женский' : 'Мужской' }}</strong></div>
        
          <v-alert border="left" color="teal" dense dark class="text-button mt-4 mb-0">Программы</v-alert>
          <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Пользователь</th>
                    <th class="text-left">Название</th>
                    <th class="text-left">Дата создания</th>
                    <th class="text-left">Дата последней тренировки</th>
                    <th class="text-left">Прогресс</th>
                  </tr>
                </thead>
                <tbody>
                  <template  v-for="(syllabus) in syllabi">
                    <tr :key="syllabus.id+'_info'">
                      <td>{{ syllabus.client.name }}</td>
                      <td>{{ syllabus.name }}</td>
                      <td>
                        <div v-if="syllabus.created">
                          {{ new Date(syllabus.created) | moment("DD.MM.YYYY HH:mm") }}
                        </div>
                      </td>
                      <td>
                        <div v-if="syllabus.tasks.last_training">
                          {{ new Date(syllabus.tasks.last_training) | moment("DD.MM.YYYY HH:mm") }}
                          </div>
                      </td>
                      <td>
                        <v-progress-circular
                            :rotate="-90"
                            :size="40"
                            :width="5"
                            :value="syllabus.days_completed_percent"
                            color="teal"
                            class="mx-2"
                          >
                          {{ syllabus.days_completed_percent }}
                        </v-progress-circular>
                      </td>
                    </tr>
                    <tr :key="syllabus.id+'_chart'">
                      <td colspan="10">
                        <trainings-chart  :syllabusId="syllabus.id" :results="results" :tasks="tasks" ></trainings-chart>
                      </td>
                    </tr>
                  </template>
                  <!-- <tr  v-for="day in tasks.days" :key="day">
                      <td colspan="10" >
                        <v-row align="center" class="my-0 order-2" dense>
                          <v-col cols="12" sm="3">
                            <div class="ml-2">День {{ day.number }}</div>
                          </v-col>
                          <v-spacer/>
                          <v-col cols="5" sm="2" lg="1">
                            <div class="text-caption grey--text">Тренингов:</div>
                            {{ day.tasks.filter((t) => { return t.type == 'training' && t.completed }).length }} / {{ day.tasks.filter(t => t.type == 'training').length }}
                          </v-col>
                          <v-col cols="5" sm="2" lg="1">
                            <div v-if="day.date">
                              <div class="text-caption grey--text">Дата:</div>
                              {{ new Date(day.date) | moment("DD.MM.YYYY") }}
                            </div>
                          </v-col>
                          <v-col  cols="auto"  >
                            <v-progress-circular
                              :rotate="-90"
                              :size="30"
                              :width="3"
                              :value="day.percent"
                              color="teal"
                              class="text-caption"
                            >
                            {{ day.percent }}
                            </v-progress-circular>
                          </v-col>            
                        </v-row>
                      </td>
                  </tr> -->
                </tbody>
              </template>
          </v-simple-table>
          
      </div>
      <div v-else>
        <v-simple-table dense class="no-print">
          <template v-slot:default>
            <thead>
                <tr>
                  <th class="text-left text-caption">ФИО</th>
                  <th class="text-left text-caption">E-mail</th>
                  <th class="text-left text-caption">Дата рождения</th>
                  <th class="text-left text-caption">Дата регистрации</th>
                  <th class="text-left text-caption">Специалист</th>
                  <th class="text-left text-caption">Группа</th>
                  <th class="text-left text-caption">Режим</th>
                  <th class="text-left text-caption">Пол</th>
                </tr>
              </thead>
            <tbody>
              <tr>
                <td><strong>{{ user.name }}</strong></td>
                <td><strong>{{ user.email }}</strong></td>
                <td><strong>{{ birthdayFormat(user.birthday) }}</strong></td>
                <td><strong>{{ birthdayFormat(user.created_at) }}</strong></td>
                <td><strong>{{ user.doctor.name }}</strong></td>
                <td><strong>{{ user.group.name }}</strong></td>
                <td><strong>{{ user.mode == 'training' ? 'По программе' : 'Свободный' }}</strong></td>
                <td><strong>{{ user.sex == 'f' ? 'Женский' : 'Мужской' }}</strong></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="mt-5"></div>
        <v-expansion-panels focusable tile accordion v-model="openItem" >
          <v-expansion-panel readonly>
            <v-expansion-panel-header @click="openItem == 0 ? openItem = null : openItem = 0" class="px-2 py-0 text-button sr-ex-panel-header sillab-list teal--text text--lighten-1" color=" ">
              Программы
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="openItem == 0" class="sr-ex-panel-content user-ex-panel-content">
              <v-expansion-panels tile accordion v-model="openSyllabusItem">
                <v-expansion-panel readonly>
                  <v-expansion-panel-header class="px-2 py-0 text-caption sr-ex-panel-header sillab-list" color="blue-grey lighten-5">
                    <template v-slot:actions class="order-1">
                      <div class="trainings__icon"></div>
                    </template>
                    <v-row align="center" class="my-0 order-2">
                      <v-col cols="12" sm="3" class="py-sm-2 pt-2 pb-1">
                        <div class="ml-sm-2">Пользователь</div>
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col cols="12" lg="3" sm="2" class="py-sm-2 py-0 ">
                        Название
                      </v-col>
                      <v-spacer/>
                      <v-divider vertical></v-divider> 
                      <v-col cols="4" lg="1" sm="2" class="py-sm-2 pb-2 pt-0">
                        Дата создания
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col cols="4" lg="1" sm="2" class="py-sm-2 pb-2 pt-0">
                        Дата последней тренировки
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col cols="auto" sm="auto" class="py-sm-2 pb-2 pt-0">
                        Прогресс
                      </v-col>
                      <v-divider vertical></v-divider>
                      
                    </v-row>
                  </v-expansion-panel-header>
                </v-expansion-panel>
                <v-expansion-panel 
                  v-for="(syllabus,index) in syllabi"
                  :key="syllabus.id"
                  class="sr-item sillab-list__item"
                  active-class="grey lighten-5">
                  <v-expansion-panel-header class="px-sm-2 px-0 py-0" @click="openSyllabusIndex = index">
                    <template v-slot:actions class="order-1">
                      <v-icon color="teal" >
                        mdi-menu-down
                      </v-icon>
                    </template>
                    <v-row align="center" class="my-0 order-2">
                      <v-col cols="12"  sm="3">
                        <div class="ml-2">{{ syllabus.client.name }}</div>
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col cols="12" lg="3" sm="2">
                        {{ syllabus.name }}
                      </v-col>
                      <v-spacer/>
                      <v-divider vertical></v-divider> 
                      <v-col cols="4" lg="1" sm="2">
                        <div v-if="syllabus.created">
                          {{ new Date(syllabus.created) | moment("DD.MM.YYYY HH:mm") }}
                        </div>
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col cols="4" lg="1" sm="2">
                        <div v-if="syllabus.tasks.last_training">
                        {{ new Date(syllabus.tasks.last_training) | moment("DD.MM.YYYY HH:mm") }}
                        </div>
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col cols="auto" sm="auto">
                        <v-progress-circular
                          :rotate="-90"
                          :size="40"
                          :width="5"
                          :value="syllabus.days_completed_percent"
                          color="teal"
                          class="mx-2"
                        >
                        {{ syllabus.days_completed_percent }}
                        </v-progress-circular>
                      </v-col>
                      <v-divider vertical></v-divider>              
                    </v-row>
                  
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="sr-ex-panel-content">
                    <syllabus-show v-if="index == openSyllabusIndex" :syllabus="syllabus"></syllabus-show>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel readonly>
            <v-expansion-panel-header @click="openItem == 1 ? openItem = null : openItem = 1" class="px-2 py-0 text-button sr-ex-panel-header sillab-list teal--text text--lighten-1" color=" ">
              Аналитика
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="openItem == 1" class="sr-ex-panel-content user-ex-panel-content">
              <v-tabs
                v-model="activeTab"
                class="custom__tabs"
                hide-slider
                show-arrows
                background-color="transparent">
                <template v-if="isExpert">
                  <v-tab active-class="teal--text text--lighten-1 custom__tab__active" class="blue-grey lighten-4 custom__tab">
                    <v-icon left>mdi-chart-areaspline</v-icon>
                    Общий отчет
                  </v-tab>
                </template>
                <v-tab active-class="teal--text text--lighten-1 custom__tab__active" class="blue-grey lighten-4 custom__tab">
                  Параметры
                </v-tab>
                <v-tab active-class="teal--text text--lighten-1 custom__tab__active" class="blue-grey lighten-4 custom__tab">
                  Тренинги
                </v-tab>
              </v-tabs>
              <v-card class="pa-3 rounded-0 flat custom__card">
                <v-tabs-items touchless v-model="activeTab">
                  <template v-if="isExpert">
                    <v-tab-item class="pt-2">
                      <general-report :userIn="user"></general-report>
                    </v-tab-item> 
                  </template>
                  <v-tab-item class="pt-2">
                    <user-parameters-report :userIn="user"></user-parameters-report>
                  </v-tab-item>  
                  <v-tab-item class="pt-2">
                    <user-trainings-report :userIn="user"></user-trainings-report>
                  </v-tab-item>   
                  <v-tab-item class="pt-2">
                    <user-trainings-report :userIn="user"></user-trainings-report>
                  </v-tab-item>    
                  
                </v-tabs-items>        
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel readonly>
            <v-expansion-panel-header @click="openItem == 2 ? openItem = null : openItem = 2" class="px-2 py-0 text-button sr-ex-panel-header sillab-list teal--text text--lighten-1" color=" ">
              Данные
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="openItem == 2" class="sr-ex-panel-content user-ex-panel-content">
              <data-content :userIn="user"></data-content>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-card>
  </div>
</template>
<script>
import PageTitle from '../../components/main/PageTitle'
import SyllabusShow from '../../components/syllabi/SyllabusShow.vue'
import GeneralReport from '../../components/analitycs/GeneralReport'
import UserTrainingsReport from '../../components/analitycs/user/TrainingsReport'
import UserParametersReport from '../../components/analitycs/user/ParametersReport'
import DataContent from '../../components/data/DataContent'
import TrainingsChart from '../../components/syllabi/TrainingsChart'
export default {
  components: {
    PageTitle,
    SyllabusShow,
    GeneralReport,
    UserTrainingsReport,
    UserParametersReport,
    DataContent,
    TrainingsChart
  },
  data: () => ({
    activeTab: 0,
    user: null,
    syllabi: [],
    openItem: null,
    openSyllabusItem: null,
    openSyllabusIndex: null,
    isPrint: false,
    results: () => { return [] },
    tasks: () => { return [] },
  }),
  created() {
    this.user = this.users.find(u => u.id == this.$route.params.id);
  },
  watch: {
    users() {
      this.user = this.users.find(u => u.id == this.$route.params.id);
    },
    user() {
      if (this.user) {
        this.getSyllabi()
      }
    }
  },
  computed: {
    users() {
      return this.$store.state.users;
    },
    isExpert() {
      return this.$store.getters.isExpert;
    },
    items() {
      return [
          {
            text: 'Главная',
            disabled: false,
            to: '/',
          },
          {
            text: 'Пользователи',
            disabled: false,
            link: true,
            href: '/users',
          },
          {
            text: this.user ? this.user.name : '',
            disabled: true,
          },
        ]
    },    
  },
  methods: {
    birthdayFormat(date) {
      if (!date) {
        return '';
      }
      return this.$moment(date).format("DD.MM.YYYY")
    },
    print() {
      this.isPrint = !this.isPrint;
      if (this.isPrint) window.print();
    },
    getSyllabi() {
      this.loading = true
      let url = `/syllabi`;
      this.$api.get(url,{
        params: {
          client_id: this.user.id,
          is_archive: false,
        }
      })
      .then(resp => {
        this.loading = false;
        this.syllabi = resp.data._embedded.syllabi;
      })
      .catch(error => {           
          this.$store.dispatch('addRequestError', error)
      })
    },
  }
}
</script>
<style lang="scss">
  .w-72 {
    width: 72px;
  }
</style>